@import "./variables";

nz-switch {
  button.ant-switch {
    height: 26px;
    line-height: 26px;
    span {
      font-family: @semibold-font-family;
    }
    .ant-switch-handle {
      top: 4px;
      left: 4px;
    }
    &.ant-switch-checked {
      background: @color-shade-slate-blue;

      .ant-switch-handle {
        left: calc(100% - 20px - 2px);
      }
    }

  }

  button.ant-switch.ant-switch-small {
    height: 22px;
    line-height: 22px;
    min-width: 44px;
    .ant-switch-handle {
      top: 5px;
      left: 5px;
    }
    &.ant-switch-checked {
      .ant-switch-handle {
        left: calc(100% - 16px - 2px);
      }
    }
  }
}
