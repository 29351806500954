// @font-face {
//   font-family: "Poppins Light";
//   font-style: normal;
//   font-weight: normal;
//   src: local("Poppins Light"), url("./assets/fonts/Poppins/Poppins-Light.ttf");
// }
// @font-face {
//   font-family: "Poppins Medium";
//   font-style: normal;
//   font-weight: normal;
//   src: local("Poppins Medium"), url("./assets/fonts/Poppins/Poppins-Medium.ttf");
// }
// @font-face {
//   font-family: "Poppins Regular";
//   font-style: normal;
//   font-weight: normal;
//   src: local("Poppins Regular"), url("./assets/fonts/Poppins/Poppins-Regular.ttf");
// }
// @font-face {
//   font-family: "Poppins SemiBold";
//   font-style: normal;
//   font-weight: normal;
//   src: local("Poppins SemiBold"), url("./assets/fonts/Poppins/Poppins-SemiBold.ttf")
// }


// @font-face {
//   font-family: "OpenSans Regular";
//   font-style: normal;
//   font-weight: normal;
//   src: local("OpenSans Regular"), url("./assets/fonts/OpenSans/OpenSans-Regular.ttf");
// }
// @font-face {
//   font-family: "OpenSans Light";
//   font-style: normal;
//   font-weight: normal;
//   src: local("OpenSans Light"), url("./assets/fonts/OpenSans/OpenSans-Light.ttf");
// }
// @font-face {
//   font-family: "OpenSans SemiBold";
//   font-style: normal;
//   font-weight: normal;
//   src: local("OpenSans SemiBold"), url("./assets/fonts/OpenSans/OpenSans-SemiBold.ttf");
// }
// @font-face {
//   font-family: "OpenSans Bold";
//   font-style: normal;
//   font-weight: normal;
//   src: local("OpenSans Bold"), url("./assets/fonts/OpenSans/OpenSans-Bold.ttf");
// }
// @font-face {
//   font-family: "OpenSans ExtraBold";
//   font-style: normal;
//   font-weight: normal;
//   src: local("OpenSans ExtraBold"), url("./assets/fonts/OpenSans/OpenSans-ExtraBold.ttf");
// }


/* open-sans-300 - latin */
@font-face {
  font-family: 'OpenSans Light';
  font-style: normal;
  font-weight: normal;
  // font-display: block;
  src: url('./assets/fonts/OpenSans_full/open-sans-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local('OpenSans Light'), local('OpenSans-Light'),
  url('./assets/fonts/OpenSans_full/open-sans-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-300.woff') format('woff'), /* Modern Browsers */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'OpenSans Regular';
  font-style: normal;
  font-weight: normal;
  // font-display: block;
  src: url('./assets/fonts/OpenSans_full/open-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('OpenSans Regular'), local('OpenSans-Regular'),
  url('./assets/fonts/OpenSans_full/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-regular.woff') format('woff'), /* Modern Browsers */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'OpenSans SemiBold';
  font-style: normal;
  font-weight: normal;
  // font-display: block;
  src: url('./assets/fonts/OpenSans_full/open-sans-v18-latin-600.eot'); /* IE9 Compat Modes */
  src: local('OpenSans SemiBold'), local('OpenSans-SemiBold'),
  url('./assets/fonts/OpenSans_full/open-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-600.woff') format('woff'), /* Modern Browsers */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'OpenSans Bold';
  font-style: normal;
  font-weight: normal;
  // font-display: block;
  src: url('./assets/fonts/OpenSans_full/open-sans-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local('OpenSans Bold'), local('OpenSans-Bold'),
  url('./assets/fonts/OpenSans_full/open-sans-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-700.woff') format('woff'), /* Modern Browsers */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-800 - latin */
//@font-face {
//  font-family: 'OpenSans ExtraBold';
//  font-style: normal;
//  font-weight: normal;
//  // font-display: block;
//  src: url('./assets/fonts/OpenSans_full/open-sans-v18-latin-800.eot'); /* IE9 Compat Modes */
//  src: local('OpenSans ExtraBold'), local('OpenSans-ExtraBold'),
//  url('./assets/fonts/OpenSans_full/open-sans-v18-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-800.woff2') format('woff2'), /* Super Modern Browsers */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-800.woff') format('woff'), /* Modern Browsers */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */ url('./assets/fonts/OpenSans_full/open-sans-v18-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
