@import "./variables.less";

// * Generates classes for each color in our color scheme
// * Sets any text & icon to be in that color
each(@color-names-list, .(@value, @key, @index) {
  .font-icon-color-@{value} {
    @color: extract(@colors-list, @index);
    color: @color;
    fa-icon,
    em.anticon {
      color: @color;
    }
  }
});

// * Same as above - with !important
each(@color-names-list, .(@value, @key, @index) {
  .font-icon-color-@{value}-important {
    @color: extract(@colors-list, @index) !important;
    color: @color !important;
    fa-icon,
    em.anticon {
      color: @color !important;
    }
    &.underlined-link-button {
      text-decoration-color: @color !important;
    }
  }
});


// * Sets any text & icon to be in that color when hovered
each(@color-names-list, .(@value, @key, @index) {
  .font-icon-hover-color-@{value}:hover {
    @color: extract(@colors-list, @index);
    color: @color;
    fa-icon,
    em.anticon {
      color: @color;
    }
    &.underlined-link-button {
      text-decoration-color: @color;
    }
  }
});

// * Same as above - with !important
each(@color-names-list, .(@value, @key, @index) {
  .font-icon-hover-color-@{value}-important:hover {
    @color: extract(@colors-list, @index) !important;
    color: @color !important;
    fa-icon,
    em.anticon {
      color: @color !important;
    }
    &.underlined-link-button {
      text-decoration-color: @color !important;
    }
  }
});

// * Sets background color
each(@color-names-list, .(@value, @key, @index) {
  .background-color-@{value} {
    @color: extract(@colors-list, @index);
    background: @color;
  }
});

// * Text highlight with custom color
each(@color-names-list, .(@value, @key, @index) {
  .text-highlight-color-@{value} {
    @color: extract(@colors-list, @index);
    background: @color;
    margin: 0 0.2em;
    padding: 0.1em 0.4em;
    font-size: 0.85em;
    border-radius: 4px;
  }
});


// * Generates margin/padding spacing classes.
// * class examples:
// * margin-right-small -> margin-right: 0.5rem;
// * padding-top-3 -> padding-top: 3rem;
each(@spacing-keys-list, .(@v-key, @k-key, @i-key) {
  each(@spacing-sides-list, .(@v-side, @k-side, @i-side) {
    each(@spacing-sizes-keys, .(@v-size, @k-size, @i-size) {
      .@{v-key}-@{v-side}-@{v-size} {
        @sizeVal: extract(@spacing-sizes-values, @i-size);
        @{v-key}-@{v-side}: @sizeVal;
      }
    });
  });
});

each(@spacing-keys-list, .(@v-key, @k-key, @i-key) {
  each(@spacing-sides-list, .(@v-side, @k-side, @i-side) {
    each(@spacing-sizes-keys, .(@v-size, @k-size, @i-size) {
      .@{v-key}-@{v-side}-@{v-size}-important {
        @sizeVal: extract(@spacing-sizes-values, @i-size);
        @{v-key}-@{v-side}: @sizeVal !important;
      }
    });
  });
});

each(@spacing-keys-list, .(@v-key, @k-key, @i-key) {
  each(@spacing-sides-list, .(@v-side, @k-side, @i-side) {
    each(@spacing-sizes-keys, .(@v-size, @k-size, @i-size) {
      .@{v-key}-@{v-side}-negative-@{v-size} {
        @sizeVal: extract(@spacing-sizes-values, @i-size);
          @{v-key}-@{v-side}: -@sizeVal;
      }
    });
  });
});



// * Generates font-size classes.
each(@font-sizes, {
  .font-size-@{key} {
    font-size: @value;
  }
});

