@import "./variables";

.ant-popover {
  .ant-popover-inner {
    border-radius: 10px;
  }
  .ant-popover-title {
    font-family: @semibold-font-family;
    font-size: @font-sizes[small];
    padding: .6rem 1rem 0;
    border-bottom: none;
  }
  .ant-popover-inner-content {
    padding-top: .5rem;
    font-size: @font-sizes[small];
    color: @color-text;
  }

}
