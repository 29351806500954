@import './variables.less';
@import './card.less';
@import './form-item.less';
@import './input.less';
@import './select.less';
@import './switch.less';
@import './checkbox.less';
@import './radio-group.less';
@import './timepicker.less';
@import './app-table.less';
@import './collapse.less';
@import './modal.less';
@import './menu.less';
@import './notification.less';
@import './button.less';
@import './chart-tooltip.less';
@import './alert.less';
@import './date-range-picker.less';
@import './tabset.less';
@import './page-header.less';
@import './badge.less';
@import './tag.less';
@import './popover.less';
@import './demo-mask';
@import './loading-bar';

* {
  -webkit-font-smoothing: antialiased;
}

.header{
  text-align: left;
  font-family: @semibold-font-family;
  letter-spacing: 0;
  font-size: @font-sizes[header];
  color: @color-text;
}

.sub-header{
  text-align: left;
  font-family: @bold-font-family;
  letter-spacing: 0;
  font-size: @font-sizes[base];
  color: @color-text;
}

app-layout main.portalRouterOutletWrapper {
  @media screen and (max-width: 980px) {
    --padding-sides: 10px;
  }
  &.big {
    @media screen and (max-width: 1220px) {
      --padding-sides: 10px;
    }
  }
}


.ant-select-selection--multiple .ant-select-selection__placeholder {
  font-size: 12px;
}

.ant-select-selection__placeholder, .ant-select-search__field__placeholder {
  font-size: 12px;
}
.ant-input-number-input:placeholder-shown {
  font-size: 12px;
}

::selection {
  background: @color-primary-mint-green !important;
  color: @color-primary-main !important;
}

