
nz-tag.ant-tag {
  border: none;
  border-radius: 15px;
  font-size: 10px;
  color: @color-primary-black;
  background: @color-shade-mid-light;
  font-family: @semibold-font-family;
  padding: 0 7px;
  line-height: 1.8em;
  &.red {
    color: @color-warning-mid-dark;
    background: @color-warning-empty;
  }
  &.ant-tag.active-status {
    background-color: @color-shade-mid-light;
  }
  &.ant-tag.inactive-status {
    background-color: @color-grey-ash;
  }
  &.ant-tag.inactive-status-ligher {
    background-color: @color-grey-disabled;
  }
}


