
  nz-time-picker-panel {

    input {
      font-size: 12px;
    }
    li {
      font-size: 12px;
      height: 30px;
    }

  }




