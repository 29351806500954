
nz-layout.cardless-layout,
.cdk-overlay-container
{
  .cardless-layout-imports();
}

.cardless-layout-imports {
  @import "../variables.less";
  @import "./card.less";
  @import "./input.less";
  @import "./app-table.less";
}

