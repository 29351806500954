
.loading-bar {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 1px;
    height: 3px;
    width: calc(100% - 2px);
    background: @color-shade-full;
    border-radius: 1px;
    transform: scaleX(0);
    transform-origin: left;
    animation: loading 1.15s linear infinite;
  }
}

@keyframes loading {
  0% {
    transform: scaleX(0);
    left: 0;
  }
  45% {
    transform: scaleX(0.8);
    left: 0;
  }
  55% {
    transform: scaleX(0.8);
    left: 20%;
  }
  100% {
    transform: scaleX(0);
    left: 100%;
  }
}
