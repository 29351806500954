
// Styles for tab inner LABEL only
nz-tabset > .ant-tabs-nav .ant-tabs-tab,
nz-tabset > .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
  font-family: @semibold-font-family;
  font-size: @font-sizes[small];
  color: @color-grey-graphite;
  span, a, fa-icon {
    font-family: @semibold-font-family;
    font-size: @font-sizes[small];
    color: @color-grey-graphite;
  }
  &.ant-tabs-tab-active {
    span, a, fa-icon, .ant-tabs-tab-btn {
      color: @color-primary-black;
    }
  }
  &:not(.ant-tabs-tab-active):hover {
    span, a, fa-icon, .ant-tabs-tab-btn {
      color: @color-primary-black;
    }
  }
}



// Styles for TAB BUTTON - top nz-card mode
nz-tabset.ant-tabs.ant-tabs-card.ant-tabs-top {
  --inverted-border-radius-color: @color-primary-white;
  &.hide-inverted-border-radius {
    --inverted-border-radius-color: transparent;
  }
  & > nz-tabs-nav.ant-tabs-nav {
    margin-bottom: 0;
    overflow: initial;
    &:before {
      border-bottom: none;
    }
    .ant-tabs-tab {
      background: transparent;
      border: none;
      margin-top: 0.25rem;
      padding: 0.6rem 1.8rem;
      border-radius: 10px 10px 0 0;
      .inverted-border-radius() {
        content: '';
        position: absolute;
        width: 0rem;
        height: 0rem;
        bottom: 0;
        background: transparent;
        rotate: 180deg;
        z-index: 1;
        transition-property: width, height;
        transition-duration: 100ms;
        transition-timing-function: ease;
        transition-delay: 0ms;
      }
      &:before {
        .inverted-border-radius();
        right: 100%;
        box-shadow: -1rem -1px 0 0rem var(--inverted-border-radius-color);
        border-top-left-radius: 1rem;
      }
      &:after {
        .inverted-border-radius();
        left: 100%;
        box-shadow: 1rem -1px 0 0rem var(--inverted-border-radius-color);
        border-top-right-radius: 1rem;
      }
      &.ant-tabs-tab-active {
        background: @color-primary-white;
        box-shadow: rgb(28 73 78 / 5%) 0px 3px 10px;
        position: relative;
        &:before,
        &:after {
          width: 2rem;
          height: 1rem;
          transition-delay: 150ms;
        }
      }
    }
  }
}


nz-tabset:not(.ant-tabs-card) {
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0 20px 0 0;
    padding: 6px 20px 10px 0;
    transition: none;
    background: transparent;
  }
  .ant-tabs-ink-bar {
    background-color: transparent;
  }


  .ant-tabs-bar {
    border-bottom: 1px solid @color-teal;

    @media screen and (max-width: 1100px) {
      display: grid;
      grid-gap: 10px;
      place-items: flex-start;
    }

    .ant-tabs-nav .ant-tabs-tab {
      margin: 0 20px 0 0;
      padding: 6px 20px 10px 0;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      transition: none;
      background: transparent;
    }

    .ant-tabs-ink-bar {
      background-color: transparent;
    }
  }
}



nz-tabset.ant-tabs-top:not(.ant-tabs-card) {
  & > .ant-tabs-nav {
    border: none;

    &:before {
      border-bottom: 1px solid @color-shade-slate-blue;
      //bottom: -1px;
    }
    .ant-tabs-nav-scroll {
      overflow: unset;
    }
    .ant-tabs-tab {
      margin: 0 !important;
      padding: 8px 20px 8px 18px;
      text-align: center;
      &.ant-tabs-tab-active {
        background: @color-shade-slate-blue;
        border-color: @color-shade-slate-blue;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 4px 4px 6px -3px rgb(0 0 0 / 20%);
        span, a, fa-icon, .ant-tabs-tab-btn {
          color: @color-primary-white;
        }
        nz-badge span {
          color: @color-text;
        }
      }
    }
    .ant-tabs-ink-bar {
      background-color: transparent;
    }
    .ant-tabs-extra-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}


// ******* VERTICAL TABSET *******
nz-tabset.ant-tabs-left:not(.ant-tabs-card) {
  display: flex;
  & > .ant-tabs-nav {
    border: none;
    display: flex;
    flex-direction: column;
    & > .ant-tabs-nav-wrap {
      flex: unset;
    }
    .ant-tabs-nav-scroll {
      overflow: unset;
      padding-bottom: 5px;
    }
    .ant-tabs-tab {
      margin: 0 !important;
      padding: 8px 20px 8px 18px;
      text-align: center;
      justify-content: center;
      width: 100%;
      &.ant-tabs-tab-active {
        background: @color-shade-slate-blue;
        border-color: @color-shade-slate-blue;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 4px 4px 6px -3px rgb(0 0 0 / 20%);
        span, a, fa-icon, .ant-tabs-tab-btn {
          color: @color-primary-white;
        }
        nz-badge span.ant-scroll-number-only {
          color: @color-text;
        }
      }
    }
    .ant-tabs-ink-bar {
      background-color: transparent;
    }
    .ant-tabs-extra-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  & > .ant-tabs-content-holder > .ant-tabs-content {
    border-left: 3px solid @color-shade-slate-blue;
    padding-left: 24px;
    flex: 1;
  }
}


nz-tabset.ant-tabs-line.dense-tabs {
  & > .ant-tabs-bar {
    .ant-tabs-nav .ant-tabs-tab {
      margin: 0 0 0 0;
    }
  }
}


