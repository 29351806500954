nz-range-picker.ant-picker-range {
  .ant-picker-input > input {
    line-height: 1;
    &:focus {
      color: @color-primary-black;
      background: @color-shade-lightest;
      padding-inline: 2px;
      font-family: @semibold-font-family;
    }
  }
  .ant-picker-active-bar {
    display: none;
  }
}
