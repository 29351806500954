
nz-content, app-layout, .ant-drawer, .ant-modal {

  // ngx-tooltip-content appears as a sibling of the external layout
  & + ngx-tooltip-content {
      --bgColor: #2F2F2F;

      &.ngx-charts-tooltip-content.type-tooltip {
        background: var(--bgColor);
        padding: 0;
        border: none;
        box-shadow:
          0px 5px 12px 0px rgba(0, 0, 0, 0.10),
          0px 21px 21px 0px rgba(0, 0, 0, 0.09),
          0px 47px 28px 0px rgba(0, 0, 0, 0.05);

        .tooltip-caret {
          &.position-right {
            border-right: 5px solid var(--bgColor);
            transform: translateX(3px);
          }
          &.position-left {
            border-left: 5px solid var(--bgColor);
          }
          &.position-top {
            border-top: 5px solid var(--bgColor);
          }
        }
      }

      .tooltip-content {
        background: var(--bgColor);
        padding: 8px 12px;
        border-radius: 8px;

        .tooltipItem, .tooltip-item, .tooltip-content {
          font-size: 12px;
          color: @color-primary-white;

          .xTickLabel {
            color: @color-primary-white;
            font-weight: bold;
            text-align: start;
          }

          .seriesValuesCont {
            display: grid;
            grid-template-columns: auto 1px auto;
            gap: 0 8px;

            & > * {
              align-self: center;
              justify-self: flex-start;
            }

            .tooltipDataColor {
              height: 60%;
              width: 1px;
            }
          }

          .singleSeriesValueCont {
            display: flex;
            align-items: center;

            .tooltipDataColor {
              height: 60%;
              width: 1px;
              margin: 8px;
            }
          }
        }
      }
  }
}
