.ant-alert {
  border-radius: 9px;

  &.ant-alert-info {
    background: @color-shade-minimum;
    border-color: @color-shade-lightest;

    .ant-alert-icon {
      color: @color-shade-full;
    }
  }

  .ant-alert-message {
    color: @color-shade-full;
  }
}

