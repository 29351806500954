label.ant-checkbox-wrapper {
  padding: 5px 0;
  font-size: 13px;
}


label.ant-checkbox-wrapper {
  font-size: @font-sizes[small];

  &.ant-checkbox-wrapper-checked {
    .ant-checkbox-checked  {
      &:after {
        border-color: @color-shade-slate-blue;
      }
      .ant-checkbox-inner {
        background: @color-shade-slate-blue;
        border-color: @color-shade-slate-blue;
      }
    }
  }
}
