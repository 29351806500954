nz-badge {
  transition: font-size 300ms ease;

  .ant-badge-status-success {
    background: #00b207;
  }

  @media screen and (max-width: 1279px) {
    font-size: 12px;
  }
}
