app-root:has(.app-layout.mask-entity-names) {

  ngx-tooltip-content .tooltipItem:not([id*="_line_"]) .xTickLabel,
  app-chart[id*="top-chart-placementId"] > div > div > ngx-charts-bar-horizontal > ngx-charts-chart > div > svg > g > g:nth-child(1),
  app-chart[id*="top-chart-dealId"] > div > div > ngx-charts-bar-horizontal > ngx-charts-chart > div > svg > g > g:nth-child(1),
  app-chart[id*="top-chart-"] app-table td[id*="-placementName"],
  app-chart[id*="top-chart-"] app-table td[id*="-dealDescription"],
  app-chart[id*="top-chart-"] app-table td[id*="-externalDealId"],

  #publisherReportingTableJava td[id*="-placementName-"],
  #dealsReportingTable td[id*="-packageDescription-"],
  #dealsReportingTable td[id*="-externalDealId-"],
  #dealsReportingTable td[id*="-dealDescription-"],
  #detailedReportTable td[id*="-placementName-"],
  #demandReportTable td[id*="-placementName-"],

  #domainBundlesTable td[id*="-domainBundle-"],
  #domainBundlesChangesTableConfig td[id*="-value-"],

  app-deal-form nz-form-control #dealsNotes,
  app-edit-placement nz-form-control #notes,
  app-inventory-package-form nz-form-control #package_description,

  app-portal-publisher-info nz-form-control:not(:has(nz-upload)),
  app-billing-information nz-form-control:not(:has(nz-upload)),
  app-portal-publisher-info app-upload-contract a.underlined-link-button,
  app-billing-information a.underlined-link-button:not([href]),
  app-user-management-update .sectionTitle:nth-of-type(1) + div:has(app-user-form-password) nz-form-control:not(:has(button)),
  app-global-search .nameAndIdCont

  {
    filter: brightness(0.95) blur(4px);
    pointer-events: none;
  }

}

