nz-table {
  position: relative;
  thead.ant-table-thead {
    & > tr > th {
      &:before {
        display: none;
      }
      .ant-table-column-sorter {
        padding-left: 10px;
      }
    }
  }

  .ant-table-tbody > tr.expand-row-open,
  .ant-table-tbody > tr.expand-row-open:hover {
    color: @color-primary-black;
    & > td {
      background: @color-shade-minimum;
      border-bottom: 1px solid @color-shade-lighter;
    }
  }
}



// styles for inner tables
#app-table {

  // prevent weird table right space
  nz-table {
    .ant-table-cell {
      &:is([class*="-fix-left"]) {
        &:is([class*="first"], [class*="last"]) {
          &:after {
            width: 0;
          }
        }
      }
    }
  }

  tr.ant-table-expanded-row > td {
    background: #fff;
    .ant-table-expanded-row-fixed {
      padding: 10px 5px;
    }
  }

  tr.ant-table-expanded-row {
    background: #fff;
  }

  td > .ant-table-row-expand-icon {
    border: 1px solid #ccc;
    border-radius: 3px;
    color: #777;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17px;
    width: 17px;
  }
  td.expandDisabled > .ant-table-row-expand-icon {
    display: none;
  }

}

nz-pagination.ant-table-pagination {
  justify-content: center;
  .ant-pagination-simple {
    float: none;
    text-align: center;
  }
}


nz-table {
  .ant-table-thead {
    tr > th,
    tr > th > .ant-table-column-title {
      font-size: @font-sizes[small];
      font-family: @semibold-font-family;
      background: @color-primary-white;
      color: @color-primary-main !important;
    }
  }
  .ant-table-tbody {
    tr {
      td {
        font-size: @font-sizes[small];
        font-family: @regular-font-family;
        color: @color-text;
      }
      &.table-totals {
        td {
          background: @color-shade-lightest;
          border-bottom: 1px solid @color-primary-main;
          font-family: @semibold-font-family;
          color: @color-primary-black;
          white-space: nowrap;
        }
      }
    }
  }

  .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
    margin-left: 4px;
  }
  .ant-select-selection--single{
    .ant-select-selection__rendered{
      margin-left:9px;
    }
  }

  .ant-table-footer {
    background: transparent;
    padding-top: 35px;

    .floated-excel{
      position: absolute;
      bottom: 15px;
      right: 28px;
    }
  }
}


nz-table.compact-table {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 4px 12px;
    border-bottom: none !important;
  }

  .ant-table-tbody > tr:nth-child(even):not(:hover) > td {
    background: @color-shade-empty;
  }
}


@media screen and (max-width: 980px) {
  app-table {
    td {
      white-space: nowrap;
    }
  }
}

.disable-table {
  filter: grayscale(1) opacity(0.5);
  pointer-events: none;
}




