
app-table nz-table:not(.compact-table) {
  .ant-table-thead > tr > th {
    border-color: @color-primary-main;
    color: @color-text;
    font-family: @semibold-font-family;
    background: @color-primary-white;
    font-size: @font-sizes[base];
    padding: 12px 16px;

    &.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
      background: transparent;
      color: @color-primary-main !important;
    }
  }

  .ant-table-tbody {
    & > .table-totals-title {
      position: absolute;
      display: flex;
      justify-content: center;
      font-family: @semibold-font-family;
      color: @color-primary-black;
      transform: translate(-50%, -60%);
      background: @color-primary-white;
      left: 50%;
      padding: 0 10px;
      line-height: 1;
    }
    & > tr {
      &.table-totals {
        td {
          background: @color-shade-lightest !important;
          border-bottom: 1px solid @color-primary-main;
          font-family: @semibold-font-family;
          color: @color-primary-black;
          white-space: nowrap;
        }

        &:hover > td {
          background: @color-primary-white;
        }
      }
    }
  }

  .ant-table-placeholder {
    background: transparent;
    border-top: none;
    border-radius: 0;
  }

  .ant-table-footer {
    background: transparent;
    padding-top: 35px;

    .floated-excel{
      position: absolute;
      bottom: 15px;
      right: 28px;
    }
  }


  // overriding simple pagination (this style:  < [1] / 3 >  )
  nz-pagination {
    justify-content: center;
    .ant-pagination-simple {
      float: none;
      text-align: center;
    }
  }


  // special table classes:
  nz-table {
    &.largeHeaderFont {
      .ant-table-thead > tr > th {
        font-size: @font-sizes[large];
        padding: 8px 16px;
      }
    }
    // prevent weird table right space
    &.ant-table-cell-fix-left-last::after {
      width: 0;
    }
  }


  .ant-table[class*="ping"] {
    &[class*="ping-left"]:not([class*="ping-right"]) {
      .ant-table-cell[class*="fix"] {
        // border-left: none;
        box-shadow: none;
      }
    }
    &[class*="ping-right"]:not([class*="ping-left"]) {
      .ant-table-cell[class*="fix"] {
        // border-right: none;
        box-shadow: none;
      }
    }
    .ant-table-cell[class*="fix"] {
      background: #fffe;
    }
    .ant-table-container::before,
    .ant-table-container::after {
      content: none;
    }
  }

}


