.ant-menu-item-selected:after,
.ant-menu-item:after,
.ant-menu-submenu-selected:after,
.ant-menu-submenu:after {
  border: none !important;
  content: none;
}


.ant-menu-submenu-popup {
  left: -20px;
  .ant-menu-vertical.ant-menu-sub {
    min-width: fit-content;
    ul {
      min-width: fit-content;
      margin-bottom: 0;

      .ant-menu-item:not(:last-child) {
        margin-bottom: 0;
      }

      & > ul {
        margin-bottom: 0;
      }
    }
  }
}
