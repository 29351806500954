nz-radio-group {
  &.ant-radio-group {
    font-size: 12px;

    label.ant-radio-button-wrapper {
      height: auto;
      padding: 6px 10px;
      font-size: 12px;
      line-height: 16px;
      color: @color-text;
      font-family: @semibold-font-family;
      background: @color-grey-background;

      &:hover {
        background: @color-grey-background;
        color: @color-primary-black;
        filter: brightness(0.97);
      }

      &.ant-radio-button-wrapper-checked {
        color: @color-primary-black;
        border-color: @color-text;
        background: @color-grey-background;
        filter: brightness(0.97);
        &:not([class*='-disabled']):first-child {
          border-right-color: @color-text;
        }
        &:not([class*='-disabled']):before {
          background-color: @color-text;
        }
        &:hover {
          background: @color-grey-background;
          color: @color-primary-black;
          border-color: @color-text;
        }
      }

      .ant-radio-checked span.ant-radio-inner:after {
        background: @color-shade-darker;
      }
    }
  }
}


nz-radio-group.segal-small-radio-group {
  span.ant-radio {
    display: none;
  }
  .ant-radio + * {
    text-transform: uppercase;
    font-size: 11px;
    color: #A0A0A0;
  }
  .ant-radio-checked + * {
    color: @color-shade-mid-dark;
  }
}
