app-auth,
nz-content,
main,
.unruly-new-design-wrapper,
.signup-wrapper {
  .card-wrapper {
    margin-bottom: 40px;
    box-shadow: rgba(28, 73, 78, 0.1) 0px 3px 10px;
    overflow: hidden;
    border-radius: 24px;

    &.firstTabActive {
      border-top-left-radius: 0;
      .ant-card {
        border-top-left-radius: 0;
      }
    }

    .ant-card-head-title {
      font-family: @semibold-font-family;
      font-size: 15px;
      letter-spacing: 0;
      color: #707070;
    }

    .segal-small-title-card {
      .ant-card-head {
        border-bottom: none;
      }

      .ant-card-head-title {
        padding: 23px 0 14px;
        font-size: 12px;
      }

      .ant-card-extra {
        padding: 23px 0 14px;
      }

      .ant-card-body {
        padding: 15px 25px 25px;
      }
    }


    &.cover-disabled {
      background: #677380;
      z-index: 100;
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.4;
    }
  }
}


nz-card {
  height: 100%;

  .top-left-back-button {
    display: inline-block;
    font-family: @semibold-font-family;
    font-size: @font-sizes[small];
    color: @color-grey-graphite;

    fa-icon {
      margin-right: 3px;
    }
  }

  &.nzcard-disabled {
    filter: opacity(0.6) grayscale(1);
    pointer-events: none;
    cursor: default;
  }

  &.body-padding-0 {
    .ant-card-body {
      padding: 0;
    }
  }
}
