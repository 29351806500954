
nz-page-header {
  nz-page-header-content {
    nz-page-header-title.ant-page-header-heading-title {
      font-family: @bold-font-family;
      font-size: @font-sizes[large];
      color: @color-text;
    }
  }
}
